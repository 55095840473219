<template>
  <div>
    <b-container fluid class="p-5">
      <b-row>
        <b-col :md="5">
          <b-card class="shadow p-3 m-2 mb-4">
            <h4>
              <b-icon icon="search"></b-icon>
              查找地区
            </h4>
            <div>
              <b-form-group
                  label="Search"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="input"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="searchAreas">搜索</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>

          <!--       创建地区信息     -->
          <b-card class="shadow p-3 m-2 mb-4">
            <h4>
              <b-icon icon="arrow-up-circle"></b-icon>
              新建地区信息
            </h4>
            <div>
              <b-form-group
                  label="Create"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="addForm"
                      type="search"
                      placeholder="Type to name a new authority"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="createArea">创建</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
        </b-col>

        <!--        整体信息-->
        <b-col :md="7">
          <b-card class="shadow m-2 p-3">
            <b-table :per-page="10" :items="areas" :fields="fields" striped responsive="sm">
              <template #cell(selections)="row">
                <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                  修改信息
                </b-button>
                <b-button size="sm" @click="row.toggleDetails">
                  {{ row.detailsShowing ? '收起' : '展开' }}详情
                </b-button>
              </template>

              <!--          删除-->

              <template #cell(action)="row">
                <div id="liveAlertPlaceholder"></div>
                <b-icon icon=" x-circle" @click="removeAreaById(row.item.id)" class="mr-1" id="liveAlertBtn"></b-icon>
              </template>
              <!--          详细信息-->
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>姓名:</b></b-col>
                    <b-col>{{ row.item.name }}</b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>工号:</b></b-col>
                    <b-col>{{ row.item.id }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>等级:</b></b-col>
                    <b-col>{{ row.item.level }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>上级归属编号:</b></b-col>
                    <b-col>{{ row.item.superiorId }}</b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
            <b-row>
              <b-col sm="5" md="6" class="my-1">
                <b-form-group
                    label="Per page"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                >
                  <b-form-select
                      @change="searchAreas"
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="6" class="my-1">
                <b-pagination
                    @change="searchAreas"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>

          <!-- 修改地区信息按钮-->

          <b-modal :id="infoModal.id" :title="infoModal.title" @hide="resetInfoModal">
            <form class="row g-3">
              <div class="col-md-6">
                <label for="inputName" class="form-label">地区名称</label>
                <input type="name" class="form-control" id="inputName" placeholder="Input Name">
              </div>
              <div class="col-md-6">
                <label for="inputId" class="form-label">编号</label>
                <input type="level" class="form-control" id="inputId" placeholder="Input id">
              </div>
              <div class="col-md-6">
                <label for="inputSuperiorId" class="form-label">上级归属编号</label>
                <input type="text" class="form-control" id="inputSuperiorId" placeholder="Input SuperiorId">
              </div>
              <div class="col-md-6">
                <label for="inputState" class="form-label">Level</label>
                <select id="inputState" class="form-select">
                  <option selected>Choose...</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck">
                  <label class="form-check-label" for="gridCheck">
                    Check me out
                  </label>
                </div>
              </div>
            </form>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {showAreasByLikeName} from '@/api'

export default {
  name: 'SearchArea',
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      addForm: '',
      input: '',

      pageSize: 5,
      areas: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {key: 'id', label: '编号'},
        {key: 'name', label: '区名'},
        {key: 'selections', label: 'selections', class: 'text-center'},
        {key: 'action', label: 'action', class: 'text-center'}

      ]
    }
  },
  mounted() {
    this.searchAreas()
  },
  methods: {
    searchAreas() {
      showAreasByLikeName(this.input, 1, 50).then(res => {
        if (res.data.status) {
          this.areas = res.data.data.content
          this.totalRows = res.data.data.totalCount
        }
      })
    },
    createArea() {

    },
    info(item, index, button) {
      this.infoModal.title = '地区信息'
      // this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    removeAreaById() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }

  }
}
</script>

<style scoped>
.mr-1 {
  margin-right: 10px;

}

.p-3 {
}

.m-2 {
}

.mb-4 {
}

</style>
